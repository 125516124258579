
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import styled from "styled-components";
import MenuButton from "../images/burger-menu.inline.svg";


const Layout = styled.div``

const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 25px;
  font-weight: 510;
  color: #17460e;
  margin-left: 30px;
  margin-right: 40px;
  padding: 10px;
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 18px);
    transform: scaleX(0);
    height: 2.5px;
    top: 2em;
    left: 10px;
    background-color: #17460e;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const ButtonsWrapperInnerDesktop = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
`;

const ButtonsWrapperOuterDesktop = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 30px;
`;

const ButtonsWrapperInnerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin-left: -15px;
  margin-top: 10px;
`

const ButtonsWrapperOuterMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin-left: 10px;
  margin-top: 30px;
`

const Navmenu = () => {

  const breakpoints = useBreakpoint()

  const [isShown, setIsShown] = useState(true);

  const isScreenBig = !breakpoints.sm;

  useEffect(() => {
    setIsShown(isScreenBig);
  }, [isScreenBig]);

  const handleClick = event => {
    setIsShown(current => !current);
  };

  const MenuButtonStyle = {
    display: isScreenBig ? "none" : "inline",
    marginLeft:"20px",
    width:"40px",
    height:"40px",
  }

  const ButtonsWrapperInner = isScreenBig ? ButtonsWrapperInnerDesktop: ButtonsWrapperInnerMobile
  const ButtonsWrapperOuter = isScreenBig ? ButtonsWrapperOuterDesktop: ButtonsWrapperOuterMobile

  return (
    <Layout>
      <ButtonsWrapperOuter>
        <MenuButton onClick={handleClick} style={MenuButtonStyle}></MenuButton>
        {isShown && <ButtonsWrapperInner>
          <StyledLink to="/">
            Home
          </StyledLink>
        </ButtonsWrapperInner>}
      </ButtonsWrapperOuter>
    </Layout>
  );
};

export default Navmenu;
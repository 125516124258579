import React from 'react';

import Navmenu from '../components/navmenu';

const Layout = ({ children }) => {
  return (
    <>
      <Navmenu/>
      <main>{children}</main>
    </>
  );
};

export default Layout;